import { IonContent, IonPage, IonAvatar, IonRow, IonCol, IonCard, IonCardHeader, IonCardContent, IonCardTitle, IonCardSubtitle, IonIcon, IonHeader, IonToolbar, IonRefresher, IonRefresherContent, RefresherEventDetail, IonSegment, IonSegmentButton, IonLabel, IonActionSheet } from '@ionic/react';
import { settingsOutline, fileTray } from 'ionicons/icons';
import './My.css';

import { useHistory } from "react-router";
import { useAuth } from "../pages/auth/authContext";
import axios from 'axios';
import React, { useState } from 'react';

const API_KEY = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoid2ViYXBwIiwibmFtZSI6IndlYmFwcCIsIkFQSV9USU1FIjoxNjc1MzU3MTExfQ.L3E5pQhhMF5LecrkC65tbY6gKJ7l-abkGx5k8Kf5wO0';
const API_URL = 'https://crm.cyberbox-chic.com/api';

const My: React.FC = () => {
  let { logOut } = useAuth()!;
  const history = useHistory();
  const myInfo: any = JSON.parse(window.localStorage.getItem("AUTH") as string);

  const [drawings, setDrawings] = useState<any>([]);
  const [settingOpen, setSettingOpen] = useState(false);
  const [selectedType, setSelectedType] = useState('all');

  // React.useEffect(() => {
  //   fetchDrawing()
  // })

  React.useEffect(() => {
    if(selectedType === 'all') {
      fetchDrawing()
    } else if (selectedType === 'assigned') {
      console.log()
      fetchAssignedDrawing()
    }
    // console.log(query.get("id"))
  }, [selectedType]);

  async function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    // setTimeout(() => {
    //   // Any calls to load data go here
      
    //   event.detail.complete();
    // }, 2000);

    if(selectedType === 'all') {
      const result = await fetchDrawing();
      if(result) {
        event.detail.complete();
      }
    } else if (selectedType === 'assigned') {
      const result = await fetchAssignedDrawing();
      if(result) {
        event.detail.complete();
      }
    }
  }

  const fetchDrawing = async () => {
    await axios.get(API_URL+'/drawing', {
      headers: {
        'authtoken': API_KEY,
      }
    }).then((response) => {
      setDrawings(response.data);
    }).catch((error) => {
      setDrawings([])
    });
    return true;
  }

  const fetchAssignedDrawing = async () => {
    await axios.get(API_URL+'/drawing/assigned/'+myInfo.staff_user_id, {
      headers: {
        'authtoken': API_KEY,
      }
    }).then((response) => {
      // console.log(response.data)
      setDrawings(response.data);
    }).catch((error) => {
      setDrawings([])
    });
    return true;
  }

  const drawingList = drawings.map((data: any) =>
    <IonCol size="12" size-sm="5" size-md="6" size-lg="3" size-xl="4" key={data.id}>
      <IonCard onClick={() => history.push(`/home?id=${data.id}`)}>
        <div className="drawing-list-image">
          <img src={data.files_json ? `https://files.cyberbox-chic.com/${data.drawing_data}` : `https://crm.cyberbox-chic.com/download/preview_image?path=modules/drawing/uploads/${data.id}/cover.jpg&type=image/jpeg`} alt={data.title} loading='lazy' />
          <>
            {data.updater_name === "not" ? (
              <div className="drawing-list-image-avatar">
                <IonAvatar>
                  <img alt={data.creater_name} src={data.creater_pic} />
                </IonAvatar>
              </div>
            ) : (
              <div className="drawing-list-image-avatar">
                <IonAvatar>
                  <img alt={data.creater_name} src={data.creater_pic} />
                </IonAvatar>
                <IonAvatar>
                  <img alt={data.updater_name} src={data.updater_pic} />
                </IonAvatar>
              </div>
            )}
          </>
        </div>
        <IonCardHeader className="drawing-card-header">
          <h6>#{data.id}</h6>
          <h5>{data.title}</h5>
          <h6>{data.customer_name}</h6>
        </IonCardHeader>

        <IonCardContent className="drawing-card-content">
          <small>Create at: {new Date(data.create_time).toLocaleString('en-HK')}<br />{ data.updater_name === "not" ? '' : 'Update at: ' + new Date(data.update_time).toLocaleString('en-HK')}</small>
        </IonCardContent>
      </IonCard>
    </IonCol>
  );

  const emptyDrawingList = (() =>
    <IonCol size="12" size-sm="5" size-md="6" size-lg="3" size-xl="4" >
      <IonCard>
        <IonCardHeader>
          <IonCardTitle><IonIcon icon={fileTray}></IonIcon></IonCardTitle>
          <IonCardSubtitle>Empty</IonCardSubtitle>
        </IonCardHeader>

        <IonCardContent>
          <small>You can pull down to reload.</small>
        </IonCardContent>
      </IonCard>
    </IonCol>
  );

  return (
    <IonPage>
      <IonHeader>
        <IonRow class="ion-justify-content-center">
          <IonCol class="my-header" size="12" size-md="12" size-lg="12" size-xl="8">
            <IonAvatar>
              <img alt={myInfo.staff_user_name} src={myInfo.staff_profile_pic} />
            </IonAvatar>
            <div>
              <div className="my-header-title">Hello, {myInfo.staff_user_name}</div>
              <div className="my-header-subTitle">{myInfo.staff_user_email}</div>
            </div>
            <div className='my-header-setting' onClick={() => setSettingOpen(true)}>
              <IonIcon icon={settingsOutline} size="large"></IonIcon>
            </div>
          </IonCol>
        </IonRow>
        <>
          <IonToolbar>
            <IonSegment value={selectedType} onIonChange={(e: any) => setSelectedType(e.detail.value)}>
              <IonSegmentButton value="all">
                <IonLabel>All</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="assigned">
                <IonLabel>Assigned</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonToolbar>
        </>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonRow class="ion-justify-content-center">
          <IonCol size="12" size-md="12" size-lg="12" size-xl="8">
            <IonRow>
              {selectedType === 'assigned' 
                  ? <IonCol size="12">
                      <IonCard className="ion-padding">
                        List out all drawings that assigned to you and not finished yet.
                      </IonCard>
                    </IonCol>
                  : ''
                }
              {drawingList.length > 0 ? drawingList : emptyDrawingList() }
            </IonRow>
          </IonCol>
        </IonRow>
      </IonContent>
      <IonActionSheet
        isOpen={settingOpen}
        header="Settings"
        buttons={[
          {
            text: 'Check Update',
            handler: () => {
              window.location.reload()
            }
          },
          {
            text: 'Logout',
            handler: async () => {
              await logOut();
              history.replace("/login");
            }
          },
          {
            text: 'Cancel',
            role: 'cancel',
            data: {
              action: 'cancel',
            },
          },
        ]}
        onDidDismiss={() => setSettingOpen(false)}
      ></IonActionSheet>
    </IonPage>
  );
};

export default My;
