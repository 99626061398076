import React from "react";
import axios from 'axios';

type UserDataInterface = { initialized: boolean; loggedIn: boolean; user: any };
type MyContextInterface = {
  authInfo: UserDataInterface;
  initialize: () => Promise<boolean>;
  logOut: () => Promise<boolean>;
  logIn: (email: string, password: string) => Promise<boolean>;
};

const API_KEY = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoid2ViYXBwIiwibmFtZSI6IndlYmFwcCIsIkFQSV9USU1FIjoxNjc1MzU3MTExfQ.L3E5pQhhMF5LecrkC65tbY6gKJ7l-abkGx5k8Kf5wO0';
const API_URL = 'https://crm.cyberbox-chic.com/api/staff/auth';


// create the context
export const AuthContext = React.createContext<MyContextInterface | undefined>(
  undefined
);


export const AuthProvider: React.FC<{ children: React.ReactNode }> = (props: any) => {
  const [authInfo, setAuthInfo] = React.useState<UserDataInterface>();
  // the reactive values
  const logOut = () => {
    return new Promise((resolve) => {
      window.localStorage.removeItem("AUTH");
      setAuthInfo({ initialized: true, loggedIn: false, user: null });
      setTimeout(() => {
        return resolve(true);
      }, 1000);
    });
  };

  const logIn = (email: string, password: string) => {
    const loginData = {
        "email": email,
        "password": password,
    }
    axios.post(API_URL, loginData, {
        headers: {
          'authtoken': API_KEY,
          'Content-Type': 'multipart/form-data',
        }
    }).then((response) => {
      const data = response.data;
      if(data?.staff_logged_in === true && typeof data?.staff_logged_in !== "undefined") {
        // console.log("logined");
        return new Promise((resolve) => {
          let v = {
            initialized: true,
            loggedIn: true,
            user: data,
          };
          setAuthInfo(v);
          window.localStorage.setItem("AUTH", JSON.stringify(v.user));
          setTimeout(() => {
            return resolve(true);
          }, 1000);
        });
      }
    }).catch((error) => {
        // Error
        // return Promise.resolve({ error });
        // console.log(error.config);
    });
  };

  const initialize = () => {
    let response = window.localStorage.getItem("AUTH") || null;
    if (response !== null) {
      setAuthInfo({
        initialized: true,
        loggedIn: true,
        user: JSON.parse(response),
      });
    } else {
      setAuthInfo({
        initialized: true,
        loggedIn: false,
        user: null,
      });
    }
  };

  let v = {
    authInfo,
    logOut: logOut,
    logIn: logIn,
    initialize,
  };

  return <AuthContext.Provider value={v} {...props} />;
};

export const useAuth = () => React.useContext(AuthContext);
