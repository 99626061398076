import { Redirect, Route } from 'react-router-dom';
import React, { useEffect } from "react";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
  IonLoading,
  useIonAlert,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { personCircle, pencil } from 'ionicons/icons';
import Home from './pages/Draw';
import My from './pages/My';
import Login from './pages/Login';
import { useAuth } from "./pages/auth/authContext";
import { useServiceWorker } from "./useServiceWorker";


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

setupIonicReact({
  swipeBackEnabled: false,
});

const App: React.FC = () => {
  
  const { authInfo, initialize } = useAuth()!;
  const [presentAlert] = useIonAlert();

  const { waitingWorker, showReload, reloadPage } = useServiceWorker();

  useEffect(() => {
    !authInfo?.initialized && (async () => await initialize())();
  },[authInfo, initialize]);

  useEffect(() => {
    if (showReload && waitingWorker) {
      presentAlert({
        header: 'Update found.',
        subHeader: 'A new version is available! Please update!',
        message: '**Save all data before update**',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'OK',
            role: 'confirm',
            handler: () => {
              reloadPage()
            },
          },
        ],
      })
    }
  }, [waitingWorker, showReload, reloadPage, presentAlert]);

  if (!authInfo || !authInfo.initialized) {
    return (
      <IonApp>
        <IonLoading isOpen={true} />
      </IonApp>
    );
  } else {
    return (
      <IonApp>
        <>
          {authInfo?.loggedIn === true ? (
            <IonReactRouter>
              <IonTabs>
                <IonRouterOutlet>
                  <Route exact path="/home">
                    <Home />
                  </Route>
                  <Route exact path="/my">
                    <My />
                  </Route>
                  <Route exact path="/">
                    <Redirect to="/home" />
                  </Route>
                  <Route exact path="/login">
                    <Redirect to="/home" />
                  </Route>
                </IonRouterOutlet>
                <IonTabBar slot="bottom">
                  <IonTabButton tab="home" href="/home">
                    <IonIcon icon={pencil} />
                    <IonLabel>Board</IonLabel>
                  </IonTabButton>
                  <IonTabButton tab="my" href="/my">
                    <IonIcon icon={personCircle} />
                    <IonLabel>Files</IonLabel>
                  </IonTabButton>
                </IonTabBar>
              </IonTabs>
            </IonReactRouter>
          ) : (
            <IonReactRouter>
              <Route path="/login" component={Login} exact />
              <Redirect from="/" to="/login" exact />
            </IonReactRouter>
          )}
        </>
      </IonApp>
    );
  }
};

export default App;
